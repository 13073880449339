var $ = jQuery = require('jquery');
var slick = require('slick-carousel');
var Cookies = require('cookies-js');
var skrollr = require('skrollr');
var bootstrap = require('bootstrap'); 

$(function () {
    var resize_blocks = function () {
        if ($(window).width() >= 768) {
            $('.equal-heights').each(function () {
                var equalizeEls = $(this).find('.equalize');
                var maxHeight = 0;
                var curHeight = 0;

                if (equalizeEls.length >= 2) {
                    $(this).find('.equalize').each(function () {
                        $(this).removeAttr('style');
                        curHeight = $(this).height();

                        if (curHeight > maxHeight) {
                            maxHeight = curHeight;
                        }
                    });

                    $(this).find('.equalize').height(maxHeight);
                }
            });
        } else {
            $('.equal-heights .equalize').removeAttr('style');
        }
    };

    var imgs = $("#images_wrapper > img").not(function () { return this.complete; });
    var count = imgs.length;

    // sliders init
    $('.slider-wrapper').slick();

    if (count) {
        imgs.load(function () {
            count--;
            if (!count) {
                resize_blocks();
            }
        });
    }

    window.addEventListener('resize', function () {
        resize_blocks();
    });

    resize_blocks();

    // init hero video
    if (!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera) && $('.home-hero').length) {
        $('.hero-video-outer').show();
        $('.hero-video').autoplay = true;
        $('.hero-video').get(0).play();
    }

    // init skrollr only on desktop
    //if (!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera) && $('.digital-transformation').length && $(document).width() >= 992) {
    //    skrollr.init({
    //        forceHeight: false
    //    });
        //$.fn.scrollView = function () {
        //    return this.each(function () {
        //        $('html, body').animate({
        //            scrollTop: $(this).offset().top - 700
        //        }, 1000);
        //    });
        //}

        //$('.digital-transformation').scrollView();
    //}

    // home hero support for mobile
    //$('.home-panel.desktop').on('mouseenter mouseleave touchup', function () {
    //    $(this).toggleClass('hover');
    //});

    // only tablet or larger
    if ($(document).width() >= 768) {
        // only open menu items by default on desktop
        $('.menu-item-toggle[data-active-desktop=true]').addClass('active');
    }

    // only desktop or larger
    if ($(document).width() >= 992 && self === top) {
        // home hero desktop behavior
        if ($('.home-hero').length) {
            var homeHero = $('.home-hero');

            homeHero.next().css({
                'margin-top': (homeHero.outerHeight() - 200) + 'px',
            });

            homeHero.siblings().css({
                'position': 'relative',
                'background-color': '#fff',
                'z-index': '9'
            });

            $('#footer').css({
                'position': 'relative',
                'background-color': '#fff',
                'z-index': '9'
            });

            $('header').css({
                'position': 'fixed',
                'z-index': '2' // fix for ie11 jumpiness
            });

            homeHero.css({
                'position': 'fixed',
                'z-index': '1' // fix for ie11 jumpiness
            });
        }
    }

    // prevent scrolling on menu on ios
    $('#main-menu').on('touchmove', function (e) {
        e.preventDefault();
    })

    // toggle menu
    $('.menu-btn').click(function (e) {
        e.preventDefault();

        $(this).toggleClass('active');
        $('#main-menu').toggleClass('active');
    });

    // toggle menu item
    $('.menu-item-toggle').click(function (e) {
        e.preventDefault();

        if ($(this).hasClass('active')) {
            $(this).removeClass('active')
        } else {
            $('.menu-item-toggle').removeClass('active');
            $(this).addClass('active');
        }
    });

    // close menu on anchors too
    $('.menu-sub a').click(function() {
        $('.menu-btn').toggleClass('active');
        $('#main-menu').toggleClass('active');
    });

    // search input
    $('#menu-search').keypress(function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();

            if ($('#menu-search').val().length > 0) {
                window.location = '/search?addsearch=' + $('#menu-search').val().toString();
            }
        }
    });

    // newsroom submit
    $('.text-search .input-group-addon').click(function (e) {
        $("form:first").submit();
    });

    // tab collapse 
    $('.nav-tabs').each(function () {
    //        var tabs = $(this).find('li')
    //        var count = tabs.length;
    //        var n = 3;
    //        var longWidth = 100 / count + n; // active tab is longer than the rest by n%
    //        var shortWidth = 100 / count - (n / (count - 1)); // take the n% from the remaining tabs
    //        tabs.css('width', shortWidth + "%");
    //        $('.nav-tabs li.active').css('width', longWidth + "%");
    //    });
    //}

    //setTabWidth();

    //// reset tab widths on bootstrap tab click event
    //$('a[data-toggle="tab"]').on('shown.bs.tab', function () {
    //    setTabWidth();
    //});

    // store the currently selected tab in the hash value
    $("ul.nav-tabs > li > a").on("shown.bs.tab", function (e) {
        var id = $(e.target).attr("href").substr(1);
        if (history.pushState) {
            history.pushState(null, null, '#' + id);
        }
        else {
            location.hash = id;
        }
    });

    // on load of the page: switch to the currently selected tab
    var hash = window.location.hash;
    $('a[data-toggle="tab"][href="' + hash + '"]').tab('show');

    // if there are errors/thank you text on a form, show that tab on page load
    $('.ErrorLabel, .InfoLabel').each(function () {
        var curTab = $(this).closest('.tab-pane').attr('id');
        $('a[data-toggle="tab"][href="#' + curTab + '"]').tab('show');
    });

    // check for cookie consent cookie
    if (typeof (Cookies.get('KodakAlarisCookieConsent')) === 'undefined') {
        $('#cookie-banner').show();
    }

    // hide cookie consent banner when button is clicked and set cookie
    $('#cookie-banner .consent-btn').click(function (e) {
        e.preventDefault();

        $.get('/api/consent/agree', function (data) {
            if (data.status === 'success') {
                $('#cookie-banner').hide();
                Cookies.set('KodakAlarisCookieConsent', 'true', { expires: Infinity, path: '/', secure: true });
            }
        });
    });

    // leadership grid
    $(".leader:not(.leader-title-block)").click(function (e) {
       var image = $(this).data("image");
        var name = $(this).find("h3").html();
        var title1 = $(this).find(".title1").html();
        var title2 = $(this).find(".title2").html();
        var bio = $(this).next(".bio").html();

        $(".leadership-detail").find(".image-wrapper").css("background-image", "url(" + image + ")");
        $(".leadership-detail").find("h1").html(name);
        $(".leadership-detail").find(".title1").html(title1);
        $(".leadership-detail").find(".title2").html(title2);
        $(".leadership-detail").find(".bio").html(bio);
        $(".leadership-detail").addClass("expanded");
        $(".leader").css("opacity", 0.0);
    });

    $(".leadership-detail").click(function () {
        $(".leadership-detail").removeClass("expanded");
        $(".leader").css("opacity", 1);
    });

    // boardofdirectors grid
    $(".board:not(.board-title-block)").click(function (e) {
        var image = $(this).data("image");
        var name = $(this).find("h3").html();
        var title1 = $(this).find(".title1").html();
        var title2 = $(this).find(".title2").html();
        var bio = $(this).next(".bio").html();

        $(".boardofdirectors-detail").find(".image-wrapper").css("background-image", "url(" + image + ")");
        $(".boardofdirectors-detail").find("h1").html(name);
        $(".boardofdirectors-detail").find(".title1").html(title1);
        $(".boardofdirectors-detail").find(".title2").html(title2);
        $(".boardofdirectors-detail").find(".bio").html(bio);
        $(".boardofdirectors-detail").addClass("expanded");
        $(".board").css("opacity", 0.3);
    });

    $(".boardofdirectors-detail").click(function () {
        $(".boardofdirectors-detail").removeClass("expanded");
        $(".board").css("opacity", 1);
    });

    // scroll to form error
    if ($('.ErrorLabel').length) {
        $('html, body').animate({
            scrollTop: ($('.ErrorLabel').offset().top - 100)
        }, 100);
    }

    //scroll to thank you message
    if ($('.InfoLabel').length) { 
        $('html, body').animate({
            scrollTop: ($('.InfoLabel').offset().top - 100)
        }, 100);
    }

    // Featured Blocks Video
    $('.featured-blocks iframe').attr('src', ''); // reset iframes on page load

    $('.featured-blocks .video-link a').click(function (e) {
        e.preventDefault();
        var parent = $(this).closest('.block-image');
        var source = $(this).data('video');
        parent.find('iframe').attr('src', source).show();
    });
});
